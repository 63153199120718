import React from "react";
import SVGIcon from 'components/SVGIcon';
import Badge from 'components/Badge';
import NavLink from 'components/menus/NavLink';
import PropTypes from 'prop-types';

export default function MiniCart({ activeCartItems, dropdown = true }) {
  return (
    <div className="dropdown">
      <a href="/checkout">
        <SVGIcon name="Cart" height={25} width={30} />
        {!!activeCartItems && activeCartItems.length > 0 && (
        <Badge pill styleVariant="danger" className="pill">
          {activeCartItems.length}
        </Badge>
        )}
      </a>
      {dropdown
        && (
        <div className="dropdown-menu dropdown-menu-right pb-0 mb-0">
          {!!activeCartItems && activeCartItems.length > 0 ? (
            <div className="rounded border-1 border-gray">
              <NavLink styleVariant="dropdown-item" className="pl-4 py-3 my-3 font-weight-bold" disabled>In Your Cart</NavLink>
              {activeCartItems.map(cartItem => (
                <NavLink styleVariant="dropdown-item" className="storefront cart border-top border-1 border-gray py-3 my-3 text-left" href={`/${cartItem.path}`} key={cartItem.id}>{cartItem.name}</NavLink>
              ))}
            </div>
          ) : (
            <NavLink styleVariant="dropdown-item" className="py-3 my-3 font-weight-bold text-left">Your cart is empty.</NavLink>
          )}
          <NavLink styleVariant="dropdown-item" className="text-white text-center rounded-bottom border border-1 border-cerulean font-weight-bold mt-4 py-4 view-cart" href="/checkout">View Cart</NavLink>
        </div>
        )}
    </div>
  );
}

MiniCart.propTypes = {
  activeCartItems: PropTypes.array,
  dropdown: PropTypes.bool
};
