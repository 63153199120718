import Factory from 'factories/_Factory';


export default class ConsumerSubscription extends Factory {
  static jsonType = 'subscription'; // TODO: Update this to `consumer_subscription` once API is updated

  static afterUpdate(record, json) {
    let newRecord = record;
    if (!!json.attributes.expiry_date || newRecord.get('expiry_date') === undefined) {
      newRecord = newRecord.set('expiry_date', new Date(json.attributes.expiry_date).getTime());
    }
    if (!!json.attributes.activation_date || newRecord.get('activation_date') === undefined) {
      newRecord = newRecord.set('activation_date', new Date(json.attributes.activation_date).getTime());
    }
    return newRecord;
  }

  static get defaults() {
    return {
      trial: false,
      product_id: null,
      organization_id: null,
      has_premium_features: false
    };
  }

  static get belongsTo() {
    return ['user', 'question_bank'];
  }
}
