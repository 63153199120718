import React from 'react';
import useWindowDimensions, { breakpoints } from 'helpers/windowDimensions';

import HomeHeroSection from './HomePage/HomeHeroSection';
import HomeAnnouncements from './HomePage/HomeAnnouncements';
import HomeAdvantage from './HomePage/HomeAdvantage';
import HomeStats from './HomePage/HomeStats';
import HomeTestimonials from './HomePage/HomeTestimonials';
import HomeReasons from './HomePage/HomeReasons';
import HomeTools from './HomePage/HomeTools';
import HomeFreeTrial from './HomePage/HomeFreeTrial';
import HomeQuestionBanks from './HomePage/HomeQuestionBanks';
import HomeCMEProfessional from './HomePage/HomeCMEProfessional';
import HomeInstitutions from './HomePage/HomeInstitutions';
import HomeFromBlog from './HomePage/HomeFromBlog';
import HomeMobileApp from './HomePage/HomeMobileApp';

export default function HomePageClass ({
  occupationsAndSpecialties,
  announcements,
  questionBankPages,
  testimonials,
  blogs,
  textContent
}) {
  const { width: screenWidth } = useWindowDimensions();
  const isLargeScreen = screenWidth >= breakpoints.lg;

  const heroTextContent = { heroTitle: textContent.hero_title, heroDescription: textContent.hero_description };
  const advantageTextContent = {
    infoSectionTitle: textContent.info_section_title,
    infoHeaderText: textContent.info_header_text,
    infoDescriptionText: textContent.info_description_text
  };
  const statsTextContent = {
    trustedInstitutionsStats: textContent.trusted_institutions_stats,
    practitionersTrainedStats: textContent.practitioners_trained_stats,
    accreditedSpecialtiesStats: textContent.accredited_specialties_stats
  };
  const reasonsTextContent = { tileSectionData: textContent.tile_section_data };
  const freeTrialTextContent = {
    freeTrialSectionTitle: textContent.free_trial_section_title,
    freeTrialHeaderText: textContent.free_trial_header_text,
    freeTrialDescriptionText: textContent.free_trial_description_text
  };
  const cmeTextContent = { cmeBlurbsData: textContent.cme_blurbs_data };
  const institutionsTextContent = {
    institutionalHeaderText: textContent.institutional_header_text,
    institutionalDescriptionText: textContent.institutional_description_text
  };

  return (
    <div className="bg-white">
      <HomeHeroSection occupationsAndSpecialties={occupationsAndSpecialties} heroTextContent={heroTextContent} />
      <HomeAnnouncements announcements={announcements} />
      <HomeAdvantage advantageTextContent={advantageTextContent} />
      <HomeStats statsTextContent={statsTextContent} />
      <HomeTestimonials testimonials={testimonials} />
      {isLargeScreen && <HomeReasons reasonsTextContent={reasonsTextContent} />}
      <HomeTools />
      <HomeFreeTrial freeTrialTextContent={freeTrialTextContent} />
      <HomeCMEProfessional cmeTextContent={cmeTextContent} />
      <HomeInstitutions institutionsTextContent={institutionsTextContent} />
      <HomeMobileApp />
      <HomeQuestionBanks questionBankPages={questionBankPages} />
      <HomeFromBlog blogs={blogs} />
    </div>
  );
}
