import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import SVGIcon from 'components/SVGIcon';
import BVLogo from 'components/BVLogo';
import Nav from 'components/menus/Nav';
import NavDropdown from 'components/menus/NavDropdown';
import NavLink from 'components/menus/NavLink';
import Button from 'components/inputs/Button';
import { clearSessionStorage } from 'config';
import SpecialtySearch from './SpecialtySearch';
import MiniCart from './MiniCart';


export default function NavigationHeaderClass({ signedIn, activeCartItems }) {
  const iconProps = { height: 20, width: 26, ariaHidden: true };
  const isIframe = window.self !== window.top;
  const [cartItems, setCartItems] = useState(activeCartItems);

  useEffect(() => {
    const handlePageShow = () => {
      // This refresh is required when backtracking from the checkout page
      fetch("/refresh-active-cart-items")
        .then(response => response.json())
        .then((updatedCartItems) => {
          setCartItems(updatedCartItems);
          return updatedCartItems;
        })
        .catch((error) => { console.error("There was a problem fetching the cart items:", error); });
    };
    window.addEventListener("pageshow", handlePageShow);
    return () => { window.removeEventListener("pageshow", handlePageShow); };
  }, []);

  const routetoRegister = (ev) => {
    window.location.href = '/users/sign_up';
  };

  if (isIframe) return null;
  return (
    <header role="banner" className="NavigationHeader">
      <div className="header-top bg-navy d-none d-lg-block d-xl-block">
        <Nav role="navigation" className="container-fluid row pr-0 d-md-flex align-items-center">
          <NavLink href="https://info.boardvitals.com/knowledge" target="_blank" className="ml-auto" active>SUPPORT</NavLink>
          <div>|</div>
          {signedIn ? <NavLink href="/users/sign_out" onClick={clearSessionStorage} active>LOG OUT</NavLink>
            : <NavLink href="/users/sign_in" active>LOG IN</NavLink>}
          {signedIn && <div>|</div>}
          {signedIn ? <NavLink href="/dashboard" active>MY DASHBOARD &gt;</NavLink>
            : <Button className="font-weight-bold mt-2 px-2 py-0" onClick={routetoRegister}>Register</Button>}
        </Nav>
      </div>
      <div className="header-bottom bg-white py-3">
        <Nav role="navigation" className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand ml-6 ml-lg-auto" href="/">
              <BVLogo light />
            </a>
            <div className="d-block d-lg-none ml-auto mr-4">
              <MiniCart activeCartItems={cartItems} dropdown={false} />
            </div>
            <button className="Button btn btn-light navbar-toggler mr-5 py-3 px-4" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <SVGIcon name="Hamburger" title="Show/Hide" height={14} width={20} />
            </button>
            <div className="collapse navbar-collapse ml-6 ml-lg-auto" id="navbarSupportedContent">
              <div className="nav navbar-nav navbar-right">
                <div className="dropdown-divider pt-2 pb-2 d-sm-block d-md-block d-lg-none d-xl-none" />
                <NavDropdown noTopMargin icon="DownCaret4" iconProps={iconProps} toggle="Board Review" toggleClassName="dropdown-toggle">
                  <NavLink styleVariant="dropdown-item" href="/medical-board-reviews">Initial Certification</NavLink>
                  <NavLink styleVariant="dropdown-item" href="/moc-recertification-exams-prep">Recertification</NavLink>
                </NavDropdown>
                <NavLink href="/cme">CME</NavLink>
                <NavLink href="/nursing">Nursing</NavLink>
                <NavLink href="/medical-students">Medical Students</NavLink>
                <NavDropdown noTopMargin icon="DownCaret4" iconProps={iconProps} toggle="More Specialties" toggleClassName="dropdown-toggle">
                  <NavLink styleVariant="dropdown-item" href="/allied-health">Allied Health</NavLink>
                  <NavLink styleVariant="dropdown-item" href="/dental">Dental</NavLink>
                  <NavLink styleVariant="dropdown-item" href="/naturopathic">Naturopathic</NavLink>
                  <NavLink styleVariant="dropdown-item" href="/pharmacy">Pharmacy</NavLink>
                  <NavLink styleVariant="dropdown-item" href="/podiatry">Podiatry</NavLink>
                  <NavLink styleVariant="dropdown-item" href="/pa-exam-prep">Physician Assistants</NavLink>
                </NavDropdown>
                <NavLink href="/institutional">Institutions</NavLink>
                <NavLink href="/blog/">Blog</NavLink>

                <div className="dropdown-divider w-160 pt-4 pb-4 d-sm-block d-md-block d-lg-none d-xl-none" />
                <div className="pb-4 d-sm-block d-md-block d-lg-none d-xl-none">
                  <NavLink href="https://info.boardvitals.com/knowledge" target="_blank">Support</NavLink>
                  {signedIn ? <NavLink href="/users/sign_out" onClick={clearSessionStorage}>Logout</NavLink>
                    : <NavLink href="/users/sign_in">Login</NavLink>}
                  {signedIn ? <NavLink href="/dashboard">My Dashboard</NavLink>
                    : <Button className="font-weight-bold ml-4 mt-4" onClick={routetoRegister}>Register</Button>}
                  <div className="ml-2 mt-6 d-flex">
                    <SpecialtySearch />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto p-0 mr-auto d-none d-lg-flex align-items-center">
            <SpecialtySearch />
          </div>
          <div className="mr-3 ml-5 d-none d-lg-flex align-items-center">
            <MiniCart activeCartItems={cartItems} />
          </div>
        </Nav>
      </div>
    </header>
  );
}

NavigationHeaderClass.propTypes = {
  signedIn: PropTypes.bool,
  activeCartItems: PropTypes.array
};
